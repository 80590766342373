import React from 'react';
import Game from './components/Game'
import './App.css';
import GoogleAnalytics from './components/GoogleAnalytics';

function App() {
  return (
    <div className="App">
        <Game/>
        <GoogleAnalytics/>
    </div>
  );
}

export default App;
