import React, { Component } from 'react'
import { FaPlay as Play, FaPause as Pause } from 'react-icons/fa'

import "../styles/Timer.scss"


class Timer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            time: 0, 
            playing: props.playing,
        }
    }

    componentDidMount() {
        this.interval = setInterval(this.passTime, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    // play = () => {
    //     if(this.props.forfeit_status || this.props.gameWon()) return
    //     this.setState({playing: true})
    //     if(this.props.onPlay) this.props.onPlay();
    //     // this.interval = setInterval(this.passTime, 1000);
    // }

    // pause = () => {
    //     if(this.props.forfeit_status || this.props.gameWon()) return
    //     this.setState({playing: false})
    //     if(this.props.onPause) this.props.onPause();
    //     // clearInterval(this.interval);
    // }

    passTime = () => {
        if(!this.props.playing || this.props.forfeit_status || this.props.gameWon()) return
        this.setState({ time: this.state.time+1 })
    }

    padNumber(number) {
        if(number<100 ) return ("0"+number).slice(-2)
    }

    render() {
        return (
            <div id="timer" className={this.props.forfeit_status ? "forfeit" : this.props.gameWon() ? "won" : ""}>
                <div>{this.padNumber(Math.floor(this.state.time / (60*60)) % 24)}:{this.padNumber(Math.floor(this.state.time / 60) % 60)}:{this.padNumber(this.state.time % 60)}</div>
                {this.props.playing ? <Pause className='button' onClick={this.props.onPause}/> : <Play className='button' onClick={this.props.onPlay}/>}
            </div>
        );
    
    }
} export default Timer;