import React, {Component} from 'react'
import {FaInfo as About, FaQuestion as FAQ, FaCog as Settings, FaGithub as Github} from 'react-icons/fa'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Switch from '@material-ui/core/Switch';

import { Input } from 'reactstrap'
import "../styles/Home.scss"
import { withStyles } from '@material-ui/core';

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0
        }
    }
   
    setPage(id) {
        this.setState({page: id})
    }

    getBody() {
        switch(this.state.page){
            case 0 : return this.about()
            case 1 : return this.faq()
            case 2 : return this.settings()
        }
    }

    about() {
        return (
            <div className="home_body">
                <div className="title" title="About">About</div>
                <div className="about">
                    <i>Versed</i> is a lyrics guessing game inspired by a trend on Sporcle 
                    where users create song quizzes without naming the song title, sometimes 
                    giving an album or artist, and ask the player to figure out the song by guessing common words 
                    to reveal lyrics as you go. In this version, songs are available via the Genius API and can 
                    be randomized from customizable playlists.
                </div>
            </div>
        )
    }

    faq() {
        return (
            <div className="home_body">
                <div className="title" title="Frequently Asked Questions">Frequently Asked Questions</div>
                <div className="faq">
                    <ul>
                        <li><b>The lyrics in a song I was playing don't seem quite right!</b></li>
                        <p>
                            The lyrics for all songs available on <i>Versed</i> come from the <a href="https://genius.com">Genius</a> API. 
                            If the lyrics are wrong on Genius, they will be wrong in the game. You can help fix mistakes by suggesting 
                            corrections on the Genius page for the song you noticed a problem in. 
                            <br/><br/>
                            Sometimes, you may notice something strange that isn't caused by the lyrics' source. If you find a song with odd
                            characters, blank words, or anything else out of place, please <a href="https://github.com/amrictor/versed/issues">submit a bug</a> by 
                            creating an issue with the "bug" tag and describing the problem you've found. Please include a link to the
                            song where you noticed the problem.
                        </p>
                        <li><b>Can you add <i>X</i> feature?</b></li>
                        <p>
                            Maybe! If you have ideas for how <i>Versed</i> could work better, please <a href="https://github.com/amrictor/versed/issues">create a feature request</a> by 
                            creating an issue with the "feature request" tag and describing what you think would make <i>Versed</i> a better experience for you.
                        </p>
                        <li><b>I want to search by albums!</b></li>
                        <p>
                            Currently this is not a feature I'm pursuing, as the <a href="https://genius.com">Genius</a> API does not 
                            include an endpoint for searching albums specifically.
                        </p>
                        <li><b>I don't want to spend more time making playlists than playing the game! Where can I find playlists that other people have made?</b></li>
                        <p>
                            I've set up <a href="https://github.com/amrictor/versed-playlists">a Github</a> for people to create playlists and share them in. Anyone 
                            can add a playlist and request to merge it into the master version of the repository.
                        </p>
                    </ul>
                </div>
            </div>
        )
    }



    settings() {

        // const StyledSwitch = withStyles({
        //     root: {
        //         '&$'
        //     }
            
        // })(Switch) 

        // const StyledLabel = withStyles({

        // })(FormLabel)

        return (
            <div className="home_body">
                <div className="title" title="Settings">Settings</div>
                <div className="settings">
                    {/* Soon, <i>Versed</i> will have some cool new configuration options that will live here! */}
                    {/* <Input type="checkbox" value={this.props.settings.playOnLoad} aria-label="Play game on page load." /> Play game on page load. */}
                    <FormControlLabel
                        control={
                        <Switch
                            checked={!this.props.settings.playOnLoad}
                            onChange={(evt) => this.props.updateSettings({...this.props.settings, playOnLoad: !evt.target.checked})}
                            color='default'
                        />
                        }
                        label="Pause game on page load."
                    />
                    <br/><br/>
                    <Input type="search" placeholder="Seed" value={this.props.settings.seed} onChange={(evt) => this.props.updateSettings({...this.props.settings, seed: evt.target.value})} />
                    <div>
                        Randomization seed; set to any sequence of characters. If you and a friend set this to the same value and use the same playlist, you'll get the same songs in the same seemingly random order. 
                    </div>
                    

                    {/* <Input type="number" value={this.props.settings.seed} aria-label="Randomization seed. If you and a friend set this to the same value and use the same playlist, you'll get the same songs in the same order." /> Randomization seed. If you and a friend set this to the same value and use the same playlist, you'll get the same songs in the same order. */}
                </div>
            </div>
        )
    }

    render() {
        return (  
            <div id="home">
                <div id="head">
                    <div className="big" title="Versed">
                        <b>Versed</b>
                    </div>
                    <div>
                        <i>How well do you know your favorite songs? Start by 
                        expanding the sidebar and searching for a song to play. 
                        Add songs to a playlist and randomize if you like a challenge.</i>
                    </div>
                </div>
                <div id="nav">
                    <About title="About" className={this.state.page===0 ? "active" : ""} onClick={()=>this.setPage(0)}/>
                    <FAQ title="Frequently asked questions" className={this.state.page===1 ? "active" : ""} onClick={()=>this.setPage(1)}/>
                    <Settings title="Settings" className={this.state.page===2 ? "active" : ""} onClick={()=>this.setPage(2)}/>
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/amrictor/versed"><Github title="View source code"/></a>
                </div>
                {this.getBody()}
            </div>
                
        );
    
    }
} export default Home;